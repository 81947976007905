<template>
  <div>
    <div class="grid w-screen h-screen grid-cols-2">
      <div class="grid w-full h-screen bg-HiTribeGreen">
        <div class="place-self-center">
          <img :src="require('@/assets/images/hittribe.svg')" alt="" />
        </div>
      </div>
      <div class="grid w-full h-screen">
        <div class="place-self-center" id="start">
          <div class="title">{{ $t("enter_kiosk_code") }}</div>

          <div class="relative mb-3">
            <input
              autofocus
              id="receiver-field"
              type="text"
              v-model="key"
              class="input"
              @keyup.enter="setKey(key)"
            />
            <div class="text-xs error" v-if="showError">
              {{ $t("kiosk_code_error") }}
            </div>
          </div>

          <div>
            <button class="btn btn-primary" @click="setKey(key)">
              {{ $t("send_kiosk_code") }}
            </button>
          </div>
          <div v-if="$store.getters.hasKey" class="mt-2">
            <button class="btn btn-secondary" @click="clearKey">
              {{ $t("clear_kiosk_code") }}
            </button>
          </div>

          <div class="flex items-center my-6 space-x-4 text-xs opacity-50">
            <div class="w-full border-b"></div>
            <div>{{ $t("or") }}</div>
            <div class="w-full border-b"></div>
          </div>

          <div class="title">{{ $t("scan_qr_code") }}</div>

          <vue-qr
            v-if="peer && peer.id"
            :text="qrUrl"
            color-dark="black"
            :size="260"
            :dotScale="1"
            backgroundColor="#fbfbfd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as APIService from "@/services/APIService";
import * as LocalstorageService from "@/services/LocalstorageService";
import Peer from "peerjs";
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },
  name: "Launch",
  data() {
    return {
      key: "",
      showError: false,
      peer: null,
      lastPeerId: null,
    };
  },
  computed: {
    qrUrl() {
      return "https://" + window.location.host + "/remote/" + this.peer.id;
    },
  },
  created() {
    // Create own peer object with connection to shared PeerJS server
    this.peer = new Peer(null, {
      debug: 2,
    });

    this.peer.on("open", () => {
      console.log("ID: " + this.peer.id);
      console.log("url: " + this.qrUrl);
      console.log("Awaiting connection...");
    });

    this.peer.on("connection", (connection) => {
      console.log("Connected to: " + connection.peer);

      this.handleNewConnection(connection);
    });

    this.peer.on("disconnected", () => {
      console.log("Connection lost. Please reconnect");
      // Workaround for peer.reconnect deleting previous id
      this.peer.id = this.lastPeerId;
      this.peer._lastServerId = this.lastPeerId;
      this.peer.reconnect();
    });

    this.peer.on("close", () => {
      console.log("Connection destroyed");
    });

    this.peer.on("error", (err) => {
      console.log(err);
    });
  },
  methods: {
    setKey(key) {
      APIService.updateData(key)
        .then(() => {
          LocalstorageService.setKey(key);
          this.$store
            .dispatch("setKey", key)
            .then(() => this.$router.push({ name: "main" }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.showError = true;

            setTimeout(() => {
              this.showError = false;
            }, 3000);
          }
        });
    },

    clearKey() {
      LocalstorageService.clearKey();
    },

    handleNewConnection(connection) {
      connection.on("data", (data) => {
        console.log("received data: ", data);
        this.key = data;

        if (data.length === 12) {
          this.setKey(data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#start {
  .title {
    color: rgba(80, 125, 223, 1);
    text-transform: uppercase;
    @apply text-2xl;
    line-height: 1.25;
  }

  .input {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 20px;
    width: 100%;
    color: #555;
    @apply outline-none;
  }

  .error {
    color: red;
    padding: 2px;
  }

  .btn {
    @apply text-base;
    border-radius: 8px;
    box-shadow: none;
    color: #fff;
    font-weight: 400;
    text-align: center;
    padding: 6px 12px;
    width: 100%;
  }

  .btn-primary {
    background-color: #507ddf;
    border-color: #507ddf;
  }

  .btn-secondary {
    background-color: rgba(127, 215, 202, 1);
    border-color: rgba(127, 215, 202, 1);
  }
}
</style>
